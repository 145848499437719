import { useEffect, useState } from 'react';

export const useClickOutside = (
    ref,
    setVisible,
) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                window.getSelection().toString().length === 0 &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setVisible(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref]);
};

export const getProportion = (percentage, maxValue, minValue) => {
    return (percentage * (maxValue - minValue)) / 100 + minValue;
};

export const loadCrispChat = () => {
    useEffect(() => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = '73719e0a-ffd6-474f-9c6e-e59478da5788';

        (function() {
            const d = document;
            let s = d.createElement('script');

            s.src = 'https://client.crisp.chat/l.js';
            s.async = 1;
            d.getElementsByTagName('head')[0].appendChild(s);
        })();
    }, []);
};

let cached = null;

export const useMemberStack = () => {
    const [memberStack, setMemberStack] = useState({
        member: null,
        loaded: false,
    });

    useEffect(() => {
        if (cached !== null) {
            setMemberStack({
                member: cached,
                loaded: true,
            });
        } else {
            let script = document.createElement('script');
            script.src = 'https://api.memberstack.io/static/memberstack.js?custom';
            script.setAttribute(
                'data-memberstack-id',
                '620a7f4fa52d209a13e12554b66a8864'
            );
            script.async = true;

            const onScriptLoad = () => {
                window.MemberStack.onReady.then((member) => {
                    cached = member;
                    setMemberStack({
                        member,
                        loaded: true,
                    });
                });
            };

            script.addEventListener('load', onScriptLoad);
            document.body.appendChild(script);

            return () => {
                script.removeEventListener('load', onScriptLoad);
            };
        }
    }, []);

    return [memberStack.member, memberStack.loaded];
};
